<template>
  <b-card>
    <template #header>
      <strong>項目設定</strong>
    </template>

    <b-row>
      <b-col md="6">
        <b-card no-body>
          <template #header>
            <div class="text-center"><strong>地點類型</strong></div>
          </template>
          <b-list-group flush>
            <b-list-group-item action v-for="item in _typelist" :key="item.sort">
              <div class="d-flex justify-content-between">
                <span class="font-weight-bold" v-if="item.enable">{{ item.content }}</span>
                <span class="font-weight-light" v-else>{{ item.content }} <b-badge class="ml-1" pill variant="secondary">關閉</b-badge></span>
                <b-dropdown right variant="link" toggle-class="text-decoration-none p-0" no-caret>
                  <template #button-content>
                    <font-awesome-icon icon="ellipsis-h" />
                  </template>
                  <b-dropdown-item @click.stop="upSort(item.sort, 0)"> <font-awesome-icon fixed-width icon="caret-up" class="mr-3" />上移 </b-dropdown-item>
                  <b-dropdown-item @click.stop="downSort(item.sort, 0)"> <font-awesome-icon fixed-width icon="caret-down" class="mr-3" />下移</b-dropdown-item>
                  <b-dropdown-item @click="editType(item.sort)"><font-awesome-icon fixed-width icon="edit" class="mr-3" />編輯</b-dropdown-item>
                  <b-dropdown-item @click="enable(item.sort, false, 0)" v-if="item.enable"><font-awesome-icon fixed-width icon="ban" class="mr-3" />關閉</b-dropdown-item>
                  <b-dropdown-item @click="enable(item.sort, true, 0)" v-else><font-awesome-icon fixed-width icon="play-circle" class="mr-3" />開啟</b-dropdown-item>
                  <b-dropdown-item @click="remove(item.sort, 0)"><font-awesome-icon fixed-width icon="trash" class="mr-3" />刪除</b-dropdown-item>
                </b-dropdown>
              </div>
            </b-list-group-item>
          </b-list-group>
          <b-button variant="link" @click="createType()">增加地點類型</b-button>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card no-body>
          <template #header>
            <div class="text-center"><strong>施工地點狀況</strong></div>
          </template>
          <b-list-group flush>
            <b-list-group-item action v-for="item in _locationStatuslist" :key="item.sort">
              <div class="d-flex justify-content-between">
                <span class="font-weight-bold" v-if="item.enable">{{ item.content }}</span>
                <span class="font-weight-light" v-else>{{ item.content }} <b-badge class="ml-1" pill variant="secondary">關閉</b-badge></span>
                <b-dropdown right variant="link" toggle-class="text-decoration-none p-0" no-caret>
                  <template #button-content>
                    <font-awesome-icon icon="ellipsis-h" />
                  </template>
                  <b-dropdown-item @click.stop="upSort(item.sort, 1)"> <font-awesome-icon fixed-width icon="caret-up" class="mr-3" />上移 </b-dropdown-item>
                  <b-dropdown-item @click.stop="downSort(item.sort, 1)"> <font-awesome-icon fixed-width icon="caret-down" class="mr-3" />下移</b-dropdown-item>
                  <b-dropdown-item @click="editLocationStatus(item.sort)"><font-awesome-icon fixed-width icon="edit" class="mr-3" />編輯</b-dropdown-item>
                  <b-dropdown-item @click="enable(item.sort, false, 1)" v-if="item.enable"><font-awesome-icon fixed-width icon="ban" class="mr-3" />關閉</b-dropdown-item>
                  <b-dropdown-item @click="enable(item.sort, true, 1)" v-else><font-awesome-icon fixed-width icon="play-circle" class="mr-3" />開啟</b-dropdown-item>
                  <b-dropdown-item @click="remove(item.sort, 1)"><font-awesome-icon fixed-width icon="trash" class="mr-3" />刪除</b-dropdown-item>
                </b-dropdown>
              </div>
            </b-list-group-item>
          </b-list-group>
          <b-button variant="link" @click="createLocationStatus()">增加施工地點狀況</b-button>
        </b-card>
      </b-col>
    </b-row>

    <template #footer>
      <b-button variant="primary" class="float-right" @click="update()"> 儲存修改 </b-button>
    </template>
    <b-modal v-model="create.show" :title="'新增' + create.title">
      <b-form-group :description="'輸入欲新增的' + create.title" :label="create.title">
        <b-form-input v-model="create.text"></b-form-input>
      </b-form-group>

      <template #modal-footer>
        <b-button variant="primary" class="float-right" @click="createAction()"> 確認新增 </b-button>
      </template>
    </b-modal>

    <b-modal v-model="edit.show" :title="'編輯' + edit.title">
      <b-form-group :description="'輸入欲編輯的' + edit.title" :label="edit.title">
        <b-form-input v-model="edit.text"></b-form-input>
      </b-form-group>

      <template #modal-footer>
        <b-button variant="primary" class="float-right" @click="editAction()"> 確認編輯 </b-button>
      </template>
    </b-modal>
    <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" no-wrap> </b-overlay>
  </b-card>
</template>
<script>
export default {
  name: "ServiceStatusSetting",
  data: () => ({
    loading: true,
    typelist: [],
    locationStatuslist: [],
    create: {
      show: false,
      text: "",
      type: 0,
      title: "",
    },
    edit: {
      show: false,
      text: "",
      sort: 0,
      type: 0,
      title: "",
    },
  }),
  computed: {
    _typelist() {
      return this.typelist.sort((a, b) => a.sort - b.sort);
    },
    _locationStatuslist() {
      return this.locationStatuslist.sort((a, b) => a.sort - b.sort);
    },
    countylist() {
      return this.datalist
        .map((p) => ({
          content: p.content,
          sort: p.sort,
          enable: p.enable,
        }))
        .sort((a, b) => a.sort - b.sort);
    },
  },
  mounted() {
    this.getServiceStatus();
  },
  methods: {
    remove(sort, type) {
      this.$bvModal
        .msgBoxConfirm("確認是否刪除此項目，此動作無法還原。", {
          title: "刪除項目",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "確認",
          cancelTitle: "取消",
          footerClass: "p-2",
          hideHeaderClose: true,
        })
        .then((value) => {
          if (value) {
            let data;
            if (type === 0) data = this.typelist;
            if (type === 1) data = this.locationStatuslist;
            let index = data.findIndex((p) => p.sort == sort);
            data.splice(index, 1);
            data = this.renewSort(data);
          }
        });
    },
    enable(sort, enable, type) {
      let datalist;
      if (type === 0) datalist = this.typelist;
      if (type === 1) datalist = this.locationStatuslist;
      let data = datalist.find((p) => p.sort == sort);
      data.enable = enable;
    },
    upSort(sort, type) {
      if (sort === 0) return;
      let data;
      if (type === 0) data = this.typelist;
      if (type === 1) data = this.locationStatuslist;

      let take = data.find((p) => p.sort === sort);
      let _up = data.find((p) => p.sort === sort - 1);
      take.sort = sort - 1;
      _up.sort = sort;
    },
    downSort(sort, type) {
      let data;
      if (type === 0) data = this.typelist;
      if (type === 1) data = this.locationStatuslist;

      if (sort === Math.max(...data.map((p) => p.sort))) return;
      let take = data.find((p) => p.sort === sort);
      let _down = data.find((p) => p.sort === sort + 1);
      take.sort = sort + 1;
      _down.sort = sort;
    },
    editAction() {
      if (this.edit.text === "") return;
      let datalist;
      if (this.edit.type === 0) datalist = this.typelist;
      if (this.edit.type === 1) datalist = this.locationStatuslist;

      if (datalist.some((p) => p.content === this.create.text)) return;
      let data = datalist.find((p) => p.sort === this.edit.sort);
      data.content = this.edit.text;
      this.edit.show = false;
    },
    createAction() {
      if (this.create.text === "") return;
      let datalist;
      if (this.create.type === 0) datalist = this.typelist;
      if (this.create.type === 1) datalist = this.locationStatuslist;

      if (datalist.some((p) => p.content === this.create.text)) return;

      let sort = datalist.length > 0 ? Math.max(...datalist.map((p) => p.sort)) : -1;
      datalist.push({
        content: this.create.text,
        sort: sort + 1,
        enable: true,
      });

      this.create.show = false;
    },
    editLocationStatus(sort) {
      this.edit.type = 1;
      this.edit.sort = sort;
      this.edit.text = this.typelist.find((p) => p.sort === sort).content;
      this.edit.title = "施工地點狀況";
      this.edit.show = true;
    },
    createLocationStatus() {
      this.create.type = 1;
      this.create.text = "";
      this.create.title = "施工地點狀況";
      this.create.show = true;
    },
    editType(sort) {
      this.edit.type = 0;
      this.edit.sort = sort;
      this.edit.text = this.typelist.find((p) => p.sort === sort).content;
      this.edit.title = "地點類型";
      this.edit.show = true;
    },
    createType() {
      this.create.type = 0;
      this.create.text = "";
      this.create.title = "地點類型";
      this.create.show = true;
    },
    getServiceStatus() {
      this.$http.get("/service/status").then((response) => {
        if (response.data.success) {
          this.typelist = response.data.result.types;
          this.locationStatuslist = response.data.result.locationStatuses;
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
        this.loading = false;
      });
    },
    update() {
      this.$http.post("/service/status", { types: this.typelist, locationStatuses: this.locationStatuslist }).then((response) => {
        if (response.data.success) {
          this.$notify({ type: "success", text: "項目設定成功" });
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
      });
    },
    renewSort(array) {
      let result = array.sort((a, b) => a.sort - b.sort);
      result.forEach((item, index) => {
        item.sort = index;
      });
      return result;
    },
  },
};
</script>